
.root {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%; 
    background-color: #323232;
    border-bottom: #BB2840 3px solid;
    text-decoration: none;
    cursor: pointer;
}

.root:hover {
    background-color: #BB2840;
}

.image { position: relative; background-color: #323232; }

.name { 
    padding: 15px; 
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Bebas Neue';
    font-size: 2.0rem;
    color: #FFFFFFFF;
    z-index: 10;
}

.label { position: absolute; top: 0; left: 0px; background: #BB2840; padding: 4px 10px; color: #fff; font-weight: bold; font-size: 1.5rem; }

.price { position: absolute; bottom: -15px; right: 5px; display: flex; align-items: center; justify-content: center; width: 50px; height: 50px; border-radius: 60px; background: #fff; box-shadow: 0 2px 8px 0 rgb(4 7 20 / 7%); color: #FD595C; font-size: 1.5rem; font-weight: bold; }

@media (min-width:960px) {
    .name { padding: 20px; }
}
