.root {
    display: flex;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    align-items: flex-start;
    padding: 12px 0;
}

.item {
    padding: 10px;
}

.link {
    text-decoration: none;
    font-weight: 700;
    color: #000;
    background: #FFFFFF;
    box-shadow: 0 3px 14px 0 rgba(4, 7, 20, .07);
    border-radius: 12px;
    padding: 0 25px;
    display: block;
    line-height: 48px;
    white-space: nowrap;
}

.active_link {
    background: #BB2840;
    color: #FFFFFF;
}

@media (min-width: 768px) {
    .root {
        justify-content: center;
        flex-wrap: wrap;
    }
}

@media (max-width: 768px) {
    .item {
        scroll-snap-align: start;
    }
}