.card {
    box-shadow: unset;
}

.product_container { padding-top: 0px; }

.image_container {
    position: relative;
    padding: 0 50px;
}

.description_container {
    padding: 26px !important;
}

.products { 
    padding: 6px;
}

.products_heading {
    margin-top: 0px;
    padding-bottom: 0px !important;
}

.products_heading h1 {
    margin-bottom: 0px;
}

.back {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #11BBFF;
    font-size: 1.6rem;
}

.back_icon { margin-right: 5px; }

.label { position: absolute; top: 0; left: 0px; background: #BB2840; padding: 4px 10px; color: #fff; font-weight: bold; font-size: 1.5rem; }

.price { position: absolute; bottom: 10px; right: 15px; display: flex; align-items: center; justify-content: center; width: 85px; height: 85px; border-radius: 60px; background: #fff; box-shadow: 0 4px 16px 0 rgb(4 7 20 / 7%); color: #FD595C; font-size: 2.1rem; font-weight: bold; }


@media (min-width:768px) {

    .description_container {
        padding: 36px 36px 36px 26px !important;
    }

    .products { 
        padding: unset ;
        margin-top: 50px; 
    }

    .products_heading {
        margin-top: 10px;
    }

}
    
 