.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    margin-right: auto;
    margin-left: auto;
}

:root {
    --sp-1: 8px;
    --sp-1-grid: 4px;
    --sp-2: 16px;
    --sp-2-grid: 8px;
    --sp-3: 24px;
    --sp-3-grid: 10px;
    --sp-4: 32px;
    --sp-4-grid: 16px;
    --sp-5: 40px;
    --sp-5-grid: 20px;
    --sp-6: 48px;
    --sp-6-grid: 24px;
    --sp-7: 56px;
    --sp-7-grid: 28px;
    --sp-8: 64px;
    --sp-8-grid: 32px;
    --sp-9: 72px;
    --sp-9-grid: 36px;
    --sp-10: 80px;
    --sp-10-grid: 40px;
}

.spacing-1, .spacing-xs-0 { max-width: 100% ; }
.spacing-1, .spacing-xs-0 > .grid { padding: 0px; }
.spacing-1, .spacing-xs-1 { max-width: calc(100% + var(--sp-1)); }
.spacing-1, .spacing-xs-1 > .grid { padding: var(--sp-1-grid); }
.spacing-2, .spacing-xs-2 { max-width: calc(100% + var(--sp-2)); }
.spacing-2, .spacing-xs-2 > .grid { padding: var(--sp-2-grid) }
.spacing-3, .spacing-xs-3 { max-width: calc(100% + var(--sp-3)); }
.spacing-3, .spacing-xs-3 > .grid { padding: var(--sp-3-grid) }
.spacing-4, .spacing-xs-4 { max-width: calc(100% + var(--sp-4)); }
.spacing-4, .spacing-xs-4 > .grid { padding: var(--sp-4-grid) }
.spacing-5, .spacing-xs-5 { max-width: calc(100% + var(--sp-5)); }
.spacing-5, .spacing-xs-5 > .grid { padding: var(--sp-5-grid) }
.spacing-6, .spacing-xs-6 { max-width: calc(100% + var(--sp-6)); }
.spacing-6, .spacing-xs-6 > .grid { padding: var(--sp-6-grid) }
.spacing-7, .spacing-xs-7 { max-width: calc(100% + var(--sp-7)); }
.spacing-7, .spacing-xs-7 > .grid { padding: var(--sp-7-grid) }
.spacing-8, .spacing-xs-8 { max-width: calc(100% + var(--sp-8)); }
.spacing-8, .spacing-xs-8 > .grid { padding: var(--sp-8-grid) }
.spacing-9, .spacing-xs-9 { max-width: calc(100% + var(--sp-9)); }
.spacing-9, .spacing-xs-9 > .grid { padding: var(--sp-9-grid) }
.spacing-10, .spacing-xs-10 { max-width: calc(100% + var(--sp-10)); }
.spacing-10, .spacing-xs-10 > .grid { padding: var(--sp-10-grid) }

.first { order: -1; }

.order-xs-0 { order: 0; }
.order-xs-1 { order: 1; }
.order-xs-2 { order: 2; }
.order-xs-3 { order: 3; }
.order-xs-4 { order: 4; }
.order-xs-5 { order: 5; }
.order-xs-6 { order: 6; }
.order-xs-7 { order: 7; }
.order-xs-8 { order: 8; }
.order-xs-9 { order: 9; }
.order-xs-10 { order: 10; }
.order-xs-11 { order: 11; }
.order-xs-12 { order: 12; }

.grid { flex-grow: 1; flex-basis: 0; }
.grid-xs-1 { max-width: 8.333333%; flex-basis: 8.333333%; }
.grid-xs-2 { max-width: 16.666667%; flex-basis: 16.666667%; }
.grid-xs-3 { max-width: 25%; flex-basis: 25%; }
.grid-xs-4 { max-width: 33.333333%; flex-basis: 33.333333%; }
.grid-xs-5 { max-width: 41.666667%; flex-basis: 41.666667%; }
.grid-xs-6 { max-width: 50%; flex-basis: 50%; }
.grid-xs-7 { max-width: 58.333333%; flex-basis: 58.333333%; }
.grid-xs-8 { max-width: 66.666667%; flex-basis: 66.666667%; }
.grid-xs-9 { max-width: 75%; flex-basis: 75%; }
.grid-xs-10 { max-width: 83.333333%; flex-basis: 83.333333%; }
.grid-xs-11 { max-width: 91.666667%; flex-basis: 91.666667%; }
.grid-xs-12 { max-width: 100%; flex-basis: 100%; }


@media (min-width:768px) {

    .grid-sm-1 { max-width: 8.333333%; flex-basis: 8.333333%; }
    .grid-sm-2 { max-width: 16.666667%; flex-basis: 16.666667%; }
    .grid-sm-3 { max-width: 25%; flex-basis: 25%; }
    .grid-sm-4 { max-width: 33.333333%; flex-basis: 33.333333%; }
    .grid-sm-5 { max-width: 41.666667%; flex-basis: 41.666667%; }
    .grid-sm-6 { max-width: 50%; flex-basis: 50%; }
    .grid-sm-7 { max-width: 58.333333%; flex-basis: 58.333333%; }
    .grid-sm-8 { max-width: 66.666667%; flex-basis: 66.666667%; }
    .grid-sm-9 { max-width: 75%; flex-basis: 75%; }
    .grid-sm-10 { max-width: 83.333333%; flex-basis: 83.333333%; }
    .grid-sm-11 { max-width: 91.666667%; flex-basis: 91.666667%; }
    .grid-sm-12 { max-width: 100%; flex-basis: 100%;  }

    .order-sm-0 { order: 0; }
    .order-sm-1 { order: 1; }
    .order-sm-2 { order: 2; }
    .order-sm-3 { order: 3; }
    .order-sm-4 { order: 4; }
    .order-sm-5 { order: 5; }
    .order-sm-6 { order: 6; }
    .order-sm-7 { order: 7; }
    .order-sm-8 { order: 8; }
    .order-sm-9 { order: 9; }
    .order-sm-10 { order: 10; }
    .order-sm-11 { order: 11; }
    .order-sm-12 { order: 12; }

    .spacing-sm-0 { max-width: 100%; }
    .spacing-sm-0 > .grid { padding: 0px; }
    .spacing-sm-1 { max-width: calc(100% + var(--sp-1)); }
    .spacing-sm-1 > .grid { padding: var(--sp-1-grid); }
    .spacing-sm-2 { max-width: calc(100% + var(--sp-2)); }
    .spacing-sm-2 > .grid { padding: var(--sp-2-grid) }
    .spacing-sm-3 { max-width: calc(100% + var(--sp-3)); }
    .spacing-sm-3 > .grid { padding: var(--sp-3-grid) }
    .spacing-sm-4 { max-width: calc(100% + var(--sp-4)); }
    .spacing-sm-4 > .grid { padding: var(--sp-4-grid) }
    .spacing-sm-5 { max-width: calc(100% + var(--sp-5)); }
    .spacing-sm-5 > .grid { padding: var(--sp-5-grid) }
    .spacing-sm-6 { max-width: calc(100% + var(--sp-6)); }
    .spacing-sm-6 > .grid { padding: var(--sp-6-grid) }
    .spacing-sm-7 { max-width: calc(100% + var(--sp-7)); }
    .spacing-sm-7 > .grid { padding: var(--sp-7-grid) }
    .spacing-sm-8 { max-width: calc(100% + var(--sp-8)); }
    .spacing-sm-8 > .grid { padding: var(--sp-8-grid) }
    .spacing-sm-9 { max-width: calc(100% + var(--sp-9)); }
    .spacing-sm-9 > .grid { padding: var(--sp-9-grid) }
    .spacing-sm-10 { max-width: calc(100% + var(--sp-10)); }
    .spacing-sm-10 > .grid { padding: var(--sp-10-grid) }

}

@media (min-width:960px) {

    .grid-md-1 { max-width: 8.333333%; flex-basis: 8.333333%; }
    .grid-md-2 { max-width: 16.666667%; flex-basis: 16.666667%; }
    .grid-md-3 { max-width: 25%; flex-basis: 25%; }
    .grid-md-4 { max-width: 33.333333%; flex-basis: 33.333333%; }
    .grid-md-5 { max-width: 41.666667%; flex-basis: 41.666667%; }
    .grid-md-6 { max-width: 50%; flex-basis: 50%; }
    .grid-md-7 { max-width: 58.333333%; flex-basis: 58.333333%; }
    .grid-md-8 { max-width: 66.666667%; flex-basis: 66.666667%; }
    .grid-md-9 { max-width: 75%;  flex-basis: 75%; }
    .grid-md-10 { max-width: 83.333333%;  flex-basis: 83.333333%; }
    .grid-md-11 { max-width: 91.666667%; flex-basis: 91.666667%; }
    .grid-md-12 { max-width: 100%;  flex-basis: 100%; }

    .order-md-0 { order: 0; }
    .order-md-1 { order: 1; }
    .order-md-2 { order: 2; }
    .order-md-3 { order: 3; }
    .order-md-4 { order: 4; }
    .order-md-5 { order: 5; }
    .order-md-6 { order: 6; }
    .order-md-7 { order: 7; }
    .order-md-8 { order: 8; }
    .order-md-9 { order: 9; }
    .order-md-10 { order: 10; }
    .order-md-11 { order: 11; }
    .order-md-12 { order: 12; }

    .spacing-md-0 { max-width: 100% ; }
    .spacing-md-0 > .grid { padding: 0px; }
    .spacing-md-1 { max-width: calc(100% + var(--sp-1)); }
    .spacing-md-1 > .grid { padding: var(--sp-1-grid); }
    .spacing-md-2 { max-width: calc(100% + var(--sp-2)); }
    .spacing-md-2 > .grid { padding: var(--sp-2-grid) }
    .spacing-md-3 { max-width: calc(100% + var(--sp-3)); }
    .spacing-md-3 > .grid { padding: var(--sp-3-grid) }
    .spacing-md-4 { max-width: calc(100% + var(--sp-4)); }
    .spacing-md-4 > .grid { padding: var(--sp-4-grid) }
    .spacing-md-5 { max-width: calc(100% + var(--sp-5)); }
    .spacing-md-5 > .grid { padding: var(--sp-5-grid) }
    .spacing-md-6 { max-width: calc(100% + var(--sp-6)); }
    .spacing-md-6 > .grid { padding: var(--sp-6-grid) }
    .spacing-md-7 { max-width: calc(100% + var(--sp-7)); }
    .spacing-md-7 > .grid { padding: var(--sp-7-grid) }
    .spacing-md-8 { max-width: calc(100% + var(--sp-8)); }
    .spacing-md-8 > .grid { padding: var(--sp-8-grid) }
    .spacing-md-9 { max-width: calc(100% + var(--sp-9)); }
    .spacing-md-9 > .grid { padding: var(--sp-9-grid) }
    .spacing-md-10 { max-width: calc(100% + var(--sp-10)); }
    .spacing-md-10 > .grid { padding: var(--sp-10-grid) }
}

@media (min-width:1200px) {

    .container { max-width: 1236px; }

    .grid-lg-1 { max-width: 8.333333%; flex-basis: 8.333333%; }
    .grid-lg-2 { max-width: 16.666667%; flex-basis: 16.666667%; }
    .grid-lg-3 { max-width: 25%;  flex-basis: 25%; }
    .grid-lg-4 { max-width: 33.333333%; flex-basis: 33.333333%; }
    .grid-lg-5 { max-width: 41.666667%; flex-basis: 41.666667%; }
    .grid-lg-6 { max-width: 50%; flex-basis: 50%; }
    .grid-lg-7 { max-width: 58.333333%; flex-basis: 58.333333%; }
    .grid-lg-8 { max-width: 66.666667%; flex-basis: 66.666667%; }
    .grid-lg-9 { max-width: 75%;  flex-basis: 75%; }
    .grid-lg-10 { max-width: 83.333333%; flex-basis: 83.333333%; }
    .grid-lg-11 { max-width: 91.666667%;  flex-basis: 91.666667%; }
    .grid-lg-12 {  max-width: 100%;  flex-basis: 100%; }

    .order-lg-0 { order: 0; }
    .order-lg-1 { order: 1; }
    .order-lg-2 { order: 2; }
    .order-lg-3 { order: 3; }
    .order-lg-4 { order: 4; }
    .order-lg-5 { order: 5; }
    .order-lg-6 { order: 6; }
    .order-lg-7 { order: 7; }
    .order-lg-8 { order: 8; }
    .order-lg-9 { order: 9; }
    .order-lg-10 { order: 10; }
    .order-lg-11 { order: 11; }
    .order-lg-12 { order: 12; }

    .spacing-lg-0 { max-width: 100% ; }
    .spacing-lg-0 > .grid { padding: 0px; }
    .spacing-lg-1 { max-width: calc(100% + var(--sp-1)); }
    .spacing-lg-1 > .grid { padding: var(--sp-1-grid); }
    .spacing-lg-2 { max-width: calc(100% + var(--sp-2)); }
    .spacing-lg-2 > .grid { padding: var(--sp-2-grid) }
    .spacing-lg-3 { max-width: calc(100% + var(--sp-3)); }
    .spacing-lg-3 > .grid { padding: var(--sp-3-grid) }
    .spacing-lg-4 { max-width: calc(100% + var(--sp-4)); }
    .spacing-lg-4 > .grid { padding: var(--sp-4-grid) }
    .spacing-lg-5 { max-width: calc(100% + var(--sp-5)); }
    .spacing-lg-5 > .grid { padding: var(--sp-5-grid) }
    .spacing-lg-6 { max-width: calc(100% + var(--sp-6)); }
    .spacing-lg-6 > .grid { padding: var(--sp-6-grid) }
    .spacing-lg-7 { max-width: calc(100% + var(--sp-7)); }
    .spacing-lg-7 > .grid { padding: var(--sp-7-grid) }
    .spacing-lg-8 { max-width: calc(100% + var(--sp-8)); }
    .spacing-lg-8 > .grid { padding: var(--sp-8-grid) }
    .spacing-lg-9 { max-width: calc(100% + var(--sp-9)); }
    .spacing-lg-9 > .grid { padding: var(--sp-9-grid) }
    .spacing-lg-10 { max-width: calc(100% + var(--sp-10)); }
    .spacing-lg-10 > .grid { padding: var(--sp-10-grid) }

}

@media (min-width:1920px) {

    .grid-xl-1 { max-width: 8.333333%; flex-basis: 8.333333%; }
    .grid-xl-2 { max-width: 16.666667%; flex-basis: 16.666667%; }
    .grid-xl-3 { max-width: 25%; flex-basis: 25%; }
    .grid-xl-4 { max-width: 33.333333%; flex-basis: 33.333333%; }
    .grid-xl-5 { max-width: 41.666667%; flex-basis: 41.666667%; }
    .grid-xl-6 { max-width: 50%; flex-basis: 50%; }
    .grid-xl-7 { max-width: 58.333333%; flex-basis: 58.333333%; }
    .grid-xl-8 { max-width: 66.666667%; flex-basis: 66.666667%; }
    .grid-xl-9 { max-width: 75%; flex-basis: 75%; }
    .grid-xl-10 { max-width: 83.333333%; flex-basis: 83.333333%; }
    .grid-xl-11 { max-width: 91.666667%; flex-basis: 91.666667%; }
    .grid-xl-12 { max-width: 100%; flex-basis: 100%; }

    .order-xl-0 { order: 0; }
    .order-xl-1 { order: 1; }
    .order-xl-2 { order: 2; }
    .order-xl-3 { order: 3; }
    .order-xl-4 { order: 4; }
    .order-xl-5 { order: 5; }
    .order-xl-6 { order: 6; }
    .order-xl-7 { order: 7; }
    .order-xl-8 { order: 8; }
    .order-xl-9 { order: 9; }
    .order-xl-10 { order: 10; }
    .order-xl-11 { order: 11; }
    .order-xl-12 { order: 12; }

    .spacing-xl-0 { max-width: 100% ; }
    .spacing-xl-0 > .grid { padding: 0px; }
    .spacing-xl-1 { max-width: calc(100% + var(--sp-1)); }
    .spacing-xl-1 > .grid { padding: var(--sp-1-grid); }
    .spacing-xl-2 { max-width: calc(100% + var(--sp-2)); }
    .spacing-xl-2 > .grid { padding: var(--sp-2-grid) }
    .spacing-xl-3 { max-width: calc(100% + var(--sp-3)); }
    .spacing-xl-3 > .grid { padding: var(--sp-3-grid) }
    .spacing-xl-4 { max-width: calc(100% + var(--sp-4)); }
    .spacing-xl-4 > .grid { padding: var(--sp-4-grid) }
    .spacing-xl-5 { max-width: calc(100% + var(--sp-5)); }
    .spacing-xl-5 > .grid { padding: var(--sp-5-grid) }
    .spacing-xl-6 { max-width: calc(100% + var(--sp-6)); }
    .spacing-xl-6 > .grid { padding: var(--sp-6-grid) }
    .spacing-xl-7 { max-width: calc(100% + var(--sp-7)); }
    .spacing-xl-7 > .grid { padding: var(--sp-7-grid) }
    .spacing-xl-8 { max-width: calc(100% + var(--sp-8)); }
    .spacing-xl-8 > .grid { padding: var(--sp-8-grid) }
    .spacing-xl-9 { max-width: calc(100% + var(--sp-9)); }
    .spacing-xl-9 > .grid { padding: var(--sp-9-grid) }
    .spacing-xl-10 { max-width: calc(100% + var(--sp-10)); }
    .spacing-xl-10 > .grid { padding: var(--sp-10-grid) }

}