.root {
    display: flex;
    flex-direction: row;
}

.root div:first-child {
    margin-right: 15px;
}

.icon_text {
    display: flex;
    align-items: center;
}

.icon_text_disabled {
    color:#B1B7C0;
}

.icon {
    margin-right: 9px;
}