.root {
    position: sticky;
    width: 100%;
    bottom: 0;
    z-index: 510;
    padding: 30px 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.cookie_link {
    color:#11BBFF;
}

.cookie_container {
    justify-content: space-between;
    align-items: center;
}

.text {
    padding-right: 100px;
}

.text div:first-child {
    margin-bottom: 8px;
}

.buttons_wrapper {
    display: flex;
    justify-content: flex-end;
}

.buttons_wrapper button {
    min-width: auto;
}

.buttons_wrapper button:first-child {
    margin-right: 15px;
}

@media (max-width: 768px) {
    .text {
        text-align: center;
        padding: 0;
    }

    .buttons_wrapper {
        margin-top: 15px;
        justify-content: center;
    }
}