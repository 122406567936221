.root {
    width: 100%;
    background: rgba(50, 50, 50, 0.76);
    border-radius: 0px;
    overflow: hidden;
}

.radius-30 { border-radius: 0px; }
.radius-20 { border-radius: 0px; }
.radius-10 { border-radius: 0px; }

/*.elevation { box-shadow: 0 3px 14px 0 rgba(4, 7, 20, .07); }*/