.socials_wrapper {
    display: flex;
    list-style: none;
    margin: 0 0 25px;
    padding: 0;
}

.social_item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EAEFF4;
    width: 35px;
    height: 35px;
    border-radius: 6px;
    position: relative;
}

.icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.social_item:not(:last-child) {
    margin-right: 20px;
}

@media (min-width: 768px) {
    .socials_wrapper {
        list-style: none;
        padding: 0;
        display: flex;
    }
}