.root {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: #323232;
    border-bottom: #BB2840 3px solid;
    text-decoration: none;
    cursor: pointer;
}

.root:hover {
    background-color: #BB2840;
}

.name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Bebas Neue';
    font-size: 2.0rem;
    color: #FFFFFFFF;
    z-index: 10;
    min-height: 86px;
   
}

@media (min-width:960px) {
    .name { font-size: 2.6rem; }
}
