.root {
    display: flex;
    align-items: center;
    padding: 15px 0 0 0;
    color: #ffffff;
    cursor: pointer;
}

.icon {
    margin-right: 9px;
}