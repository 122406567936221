
.root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.page {
    height: 400px
}