.grid {
    margin-top: 46px;
}

.grid:last-child {
    margin-top: 23px;
    margin-bottom: 46px;
}

.card {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    background: rgba(50, 50, 50, 0.76);
    border-bottom: #BB2840 3px solid;
    padding: 40px;

}

.text {
    text-align: center !important;
}

.formContainer {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.form {
    width: 540px;
}

.error { padding: 15px 0; color: #B72C47; }
.success { padding: 15px 0; color: #1AA77E; }

.uploadField { display: none; }
.uploadLabel {
    display: flex;
    align-items: center;

    margin: 0 0 20px 0;
    cursor: pointer;
}

.uploadLabel > span  {
    margin-right: 10px;
}