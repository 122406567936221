.root {
    margin-bottom: 40px;
}

.card {
    background: rgba(50, 50, 50, 0.76);
    border-bottom: #BB2840 3px solid;
    min-height: 400px;
}

.text_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 26px;
}

.title_prefix {
    display: inline-block;
    margin-bottom: 30px;
    text-align: center;
    font-size: 2.6rem;
    color: #BB2840;
    text-decoration: none;
}

.title {
    margin-bottom: 30px;
    text-align: center;
    font-size: 4rem;
    text-decoration: none;
}

.text {
    margin-bottom: 40px;
    text-decoration: none;
}

.linkText {
    color: #BB2840;
    font-size: 1.8rem;
    text-decoration: none;
}

.image {
    width: 100%;
}

@media (min-width: 768px) {

    .text_container {
        padding: 26px;
    }

}