.terms_link {
    text-decoration: none;
    color: #fff;
}

.terms_link:first-child {
    padding-right: 10px;
    position: relative;
}

.terms_link:first-child:after {
    content: '';
    height: 9px;
    width: 1px;
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 0;
}

.terms_link:last-child{
    padding-left: 10px;
}