.root {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px;
    width: 100%;
    z-index: 500;
}

.button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    line-height: 1.8rem;
    box-shadow: 0 5px 20px 0 rgba(4, 7, 20, .25);
}

.icon {
    position: absolute;
    right: 15px;
    width: 14px;
    height: 12px;
    margin-left: auto;
}

/*@media (min-width:768px) {
    .root { display: none; }
}*/
