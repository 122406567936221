.root { display: flex; overflow-x: auto; overflow-y: hidden; width: 100%; }
.root { scroll-snap-type: x mandatory; -webkit-overflow-scrolling: touch; }

.slide { max-width: 44.44444444444444%; flex: 0 0 44.44444444444444%; padding: 10px; }
.slide { scroll-snap-align: start; }

@media (min-width:768px) {
    .slide { max-width: 28%; flex: 0 0 28%; padding: 12.5px; }
}

@media (min-width:960px) {
    .slide { max-width: 16.66666666666667%; flex: 0 0 16.66666666666667%; }
    .count-5 { max-width: 20%; flex: 0 0 20%; }
}
