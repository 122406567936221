.list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.day {
    display: inline-block;
    width: 30px;
}

.bold {
    font-weight: bold;
}