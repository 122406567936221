.padding-10 {
    padding: 10px;
}

.padding-20 {
    padding: 20px;
}

.padding-30 {
    padding: 30px;
}

.imageContainer {
    position: relative;
    height: 0;
    padding-bottom: 61.54%;
}

.imageContainer img {
    border-radius: 10px;
}

.title {
    font-weight: 700;
    font-size: 2.0rem;
    margin-bottom: 15px;
}

.text_container {
    padding-right: 20px;
    line-height: 2.4rem;
}

@media (min-width: 768px) {
    .text_container {
        padding: 36px 26px 0 36px;
    }
}

@media (max-width: 768px) {
    .card {
        padding: 0 !important;
        position: relative;
        overflow: hidden;
    }

    .card:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        background: linear-gradient(to bottom, transparent 0%, #000 100%);
      }

    .text_container {
        position: absolute;
        padding-right: 40px;
        bottom: 20px;
        left: 20px;
        z-index: 9;
        color: #FFFFFF;
    }

    .title {
        font-size: 1.6rem;
        margin-bottom: 5px;
    }

    .text {
        font-size: 1.4rem;
    }
}