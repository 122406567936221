.root {
    margin-bottom: 20px;

}

.background {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 180px;
}

.intro {
    width: 100%;
    margin-top: 15px;

}

.intro h1 {
    text-align: center;
}

.intro p {
    text-align: center !important;
}

.locationSearch {
    width: 100%;
    margin-top: 15px;
}

.button {
    display: inline-block;
    margin-top: 150px;

    text-align: center;
    text-decoration: none;
    border: 0;
    font-weight: bold;
    border-radius: 6px;
    padding: 15px;
    width: 100%;
    cursor: pointer;
    font-size: 1.6rem;
    background: #BB2840;
    color: #ffffff;
}

@media (min-width: 768px) {

    .root {
        display: flex;
        height: 604px;
    }

    .background {
        display: flex;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        background-position-y: unset;
        padding: 16px;
    }

    .intro {
        width: 390px;
        margin-top: 15px;
    }

    .intro h1 {
        text-align: left;
    }
    
    .intro p {
        text-align: left !important;
    }
    

    .locationSearch {
        width: 390px;
        margin-top: 15px;
    }

    .button {
        margin-top: 15px;
    
    }
}