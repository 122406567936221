
.root {
    display: flex;
    flex-direction: column;
    position: relative; 
    width: 100%;
}

.input_container {
    position: relative;
    width: 100%;
    margin-bottom: 15px;
}

.input {
    margin: 0;
    padding: 15px;
    border: 1px solid #D1D7DF;
    background: #ffffff;
    color: #B1B7C0;
    outline: none;
    border-radius: 6px;
    box-shadow: none;
    width: 100%;
}

.input_error {
    border: 1px solid #FF595C;
    background: #FFE8DD;
}

.button {
    text-align: center;
    text-decoration: none;
    border: 0;
    font-weight: bold;
    border-radius: 6px;
    padding: 15px;
    min-width: 100%;
    cursor: pointer;
    font-size: 1.6rem;
    background: #BB2840;
    color: #ffffff;
}

.clear {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EAEFF4;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    cursor: pointer;
}

@media (min-width:768px) {
    
    .root {
        flex-direction: row;
    }

    .input_container {
        margin-bottom: 0px;
    }

    .input {
        border-radius: 6px 0px 0px 6px;
        flex-grow: 1;
        border-right: 0;
    }

    .button {
        border-radius: 0px 6px 6px 0px;
        min-width: 105px;
    }
}