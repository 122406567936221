.container {
    justify-content: center;
}

.imageContainer {
    position: relative;
}

.imageContainer img {
    border-radius: 0px;
}

.title {
    font-weight: 700;
    font-size: 2.0rem;
    margin-bottom: 15px;
}

.chevron:before {
	border-style: solid;
    border-color: #FDC619;
	border-width: 2px 2px 0 0;
	content: '';
	display: inline-block;
	height: 10px;
	position: absolute;
	top: 0;
    right: 0;
    bottom: 0;
	left: 0;
    margin: auto;
	transform: rotate(45deg);
	vertical-align: top;
	width: 10px;
}

.card {
    position: relative;
    border-bottom: #BB2840 3px solid;
}

@media (min-width: 768px) {
    .text_container {
        padding: 25px;
    }

    .imageContainer {
        height: 0;
        padding-bottom: 61.54%;
    }

    .chevron {
        display: none;
    }

    .banner_link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }

    .card { 
        min-height: 390px;
    }
}

@media (max-width: 768px) {
    .text_container {
        padding: 36px 40px 0 36px;
    }

    .title {
        font-size: 1.6rem;
        margin-bottom: 5px;
    }

    .text {
        font-size: 1.4rem;
    }

    .banner_container {
        padding: 0;
    }

    .banner_container:not(:last-child) {
        margin-bottom: 25px;
    }

    .padding_10 {
        margin: -10px 0 -10px -10px;
    }

    .padding_20 {
        margin: -20px 0 -20px -20px;
    }

    .padding_30 {
        margin: -30px 0 -30px -30px;
    }

    .imageContainer img {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .chevron {
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        width: 30px;
        height: 30px;
        margin: auto 0;
    }
}

@media (max-width: 600px) {
    .text_container {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .text {
        max-width: 80%;
    }
}