.page_header {
    padding: 25px 0;
    box-sizing: border-box;
    background: #202020;
}

.container {
    align-items: center;
    position: relative;
}

@media (max-width: 768px) {
    .page_header {
        padding: 15px 20px;
        border-radius: 0;
        z-index: 999999;
    }

    .container {
        justify-content: space-between;
    }
}