.root {
    display: inline-flex;
    border-radius: 50px;
    overflow: hidden;
    border: 1px solid #626D81;
}

.button {
    text-align: center;
    text-decoration: none;
    border: 0;
    border-radius: 0px;
    padding: 7px 40px;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: bold;
    color: #626D81;
}

.selected {
    background: #626D81;
    color: #FFFFFF;
}