.root {
    box-shadow: 0;
    margin-bottom: 15px;
}

.breadcrumb {
    display: none;
}

.info {
    position: relative;
    display: inline-flex;

    padding: 20px;
    z-index: 10;
}

.image {
    width: 100%;
}

.heading {
    margin-bottom: 0px;
}

.favourite_phrase {
    font-size: 1.6rem;
    font-weight: bold;
    color: #FDC619;
    margin-bottom: 10px;
}

.align_right {
    display: flex;
    justify-content: flex-end;
}

.line {
    margin-bottom: 20px;
}

.link {
    color: #11BBFF;
    text-decoration: none;
}

.buttons {
    display: flex;
    justify-content: center;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.note {
    display: inline-flex;
    font-size: 1.4rem;
	line-height: 1.8rem;
    font-style: italic;
    margin: 15px 0px;
    color: #878D97;
}

.button {
    min-width: 100%;
}

.services {
    margin-top: 20px;
}

.align_right {
    display: flex;
    justify-content: flex-end;
}

.adres {
    margin-bottom: 20px
}

.loading {
    display: flex;
    justify-content: center;
    height: 200px;
    margin: 20px 0;
}

.extra_info {
    order: 3;
    padding: 0;
    background: unset;
    box-shadow: unset;
    border-radius: unset;
}

.extra_image {
    width: 100%;
}

.extra_info_text {
    margin-top: 20px;
}

.heading_times {
    margin-bottom: 10px;
}

.info_no_image {
    margin-top: 0px;
}

@media (min-width:768px) {

    .root {
        padding: 16px;
    }

    .breadcrumb {
        display: flex;
    }

    .info {
        display: flex;
        margin-top: 0px;
        padding: 35px;
    }

    .heading {
        margin-bottom: 15px;
    }

    .buttons {
        margin-top: 10px;
    }

    .button {
        min-width: 190px;
    }
    
    .center {
        justify-content: unset;
    }

    .services {
        margin-top: 0px;
    }

    .extra_info {
        width: 100%;
    }

    .extra_info_image { padding-right: 10px; }
    .extra_info_text { 
        margin-top: unset;
        padding-left: 10px; 
    }
}