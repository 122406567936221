.root {
    position: relative;
}

.map_container {
    padding: 10px;
    height: 500px;
}

.map_container.hide {
    display: none;
}

.map {
    overflow: hidden;
}

.no_pointer {
    pointer-events: none;
}

@media (min-width:1200px) {

    .root {
        height: 1030px;
    }
    
    .map_container {
        height: 100%;
        width: 100%;
        z-index: 100;
    }

    .map_container.hide {
        display: block;
    }

    .map_wrapper {
        position: relative;
    }

    .list_container {
        position: absolute;
        top: 0;
        right: 70px;
        bottom: 0;
        left: 70px;
        height: 1030px;
    }

}