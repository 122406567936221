.root {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    /*padding-left: 15px;*/
}

.chevron {
    margin: 0 8px;
}

.link {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);
}

.link:last-child {
    color: #fff;
}