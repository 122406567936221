
.field {
    position: relative; 
    margin: 0 0 20px 0;
}

.label {
    display: inline-block;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 1.4rem;
}

.select {
    width: 100%;
    margin: 0;
    padding: 15px;
    border: 1px solid #D1D7DF;
    background: #ffffff;
    color: #B1B7C0;
    outline: none;
    border-radius: 6px;
    box-shadow: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.select:not([multiple]) {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.153' height='6.577' viewBox='0 0 11.153 6.577'%3E%3Cpath id='Stroke_1' data-name='Stroke 1' d='M8.325,0,4.162,4.162,0,0' transform='translate(1.414 1.414)' fill='none' stroke='%231bf' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='2'/%3E%3C/svg%3E%0A");
	background-position: right 12px center;
    background-repeat: no-repeat;
	background-size: 10px 8px;
	padding-right: 25px;
}

.input_error,
:global(form.validated) .select:invalid {
    border: 1px solid #FF595C;
    background: #FFE8DD;
}

.loading {
    width: 100%;
    margin: 0;
    padding: 13px;
    border: 1px solid #D1D7DF;
    background: #ffffff;
    color: #B1B7C0;
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;

}