.footer_links_wrapper {
    padding: 0;
    margin: 0;
    list-style: none;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    max-width: 400px;
}

.list_item:not(:last-child) {
    margin-bottom: 20px;
}

.nav_link {
    text-decoration: none;
    color: #fff;
    position: relative;

}