.root {
    font-size: 1.6rem;
    line-height: 1.8rem;
}

.bold {
    font-weight: bold;
}

.green {
    color: #24D880;
    font-weight: bold;
}