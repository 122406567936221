.root {
    margin-bottom: 50px;
}

.form {
    padding: 18px 20px 50px 20px;
}

.button {
    display: flex;
    justify-content: flex-end;
}

.loading {
    height: 110px;
}

.form_field { padding: 0; }

.form label[for="policy"] span { font-weight: 400; }
.form label[for="policy"] a { color: #11BBFF; }

.send { width: 100%; }

.error { padding: 15px 0; color: #B72C47; }
.success { padding: 15px 0; color: #1AA77E; }

@media (min-width:768px) {
    .form {
        padding: 18px 0;
        margin: 0px 18px 34px 18px;
    }

    .form_field { padding: 0 16px; }

    .send { width: unset; }
}