.icon_wrapper {
    padding: 6px;
    line-height: 0;
    margin-left: 45px;
    cursor: pointer;
}

.menu_wrapper {
    display: none;
}

.menu_item_list {
    padding: 30px 0;
    margin: 0;
    list-style: none;
}

.menu_item {
    position: relative;
    padding: 10px 0;
}

.nav_link {
    font-size: 2rem;
    text-decoration: none;
    color: #fff;
    font-family: 'Bebas Neue';
}

.nav_link.meta_link {
    font-size: 2rem;
}

.active_link {
    color: #F7C037;
}

.menu_wrapper_closed {
    display: none;
}

@media (max-width: 768px) {
    .menu_open {
        overflow-y: hidden;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 9999;
    }
    
    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(25, 36, 72, 0.6);
        cursor: pointer;
        opacity: 0;
        transition: opacity 0.2s linear;
    }
    .modal.open .background {
        opacity: 1;
    }
    
    .details {
        display: flex;
        flex-direction: column;
        position: absolute;
        padding-top: 75px;
        top: 0;
        right: 0;
        width: calc(100% - 60px);
        height: 100%;
        background: #202020;
        transform: translateX(100%);
        transition: transform 0.2s ease-out;
    }
    .modal.open .details {
        transform: translateX(0);
    }

    .nav_link {
        padding-left: 40px;
    }

    .bottom_container {
        padding: 0 40px;
    }

    .menu_wrapper_open {
        display: none;
    }
}

@media (min-width: 768px) {
    .icon_wrapper {
        margin-right: 32px;
        display: none;
    }

    .menu_wrapper_open {
        position: absolute;
        right: 8px;
        top: 55px;
        z-index: 999;
    }

    .menu_wrapper_open:before {
        content: '';
        display: inline-block;
        border-top: 20px solid #333333;
        pointer-events: none;
        border-right: 20px solid transparent;
        transform: rotate(45deg);
        position: absolute;
        top: -6px;
        right: 30px;
    }

    .menu_item_list {
        min-width: 240px;
        padding: 30px 35px;
        background-color: #333333;
    }
}