
.root {
    display: flex;
    flex-direction: column;
    z-index: 999;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background: rgba(0, 0, 0, 0.75);
    margin-bottom: 30px;
    overflow: hidden;
}

.header h2 { 
    align-self: flex-start;
}


.content.map {
    display: none;
}

.toggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.no_result {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mylocation {
    margin-top: 15px;
}

@media (min-width:1200px) {

    .root {
        margin-top: 30px;
        height: 940px;
        width: 520px;
        overflow: hidden;
    }

    .header {
        height: 210px;
        padding: 35px;

        margin-bottom: 0;
        border-radius: 0;
        box-shadow: unset;
        overflow: visible;
    }

    .header h2 { 
        align-self:unset;
    }

    .toggle {
        display: none;
    }

    .content {
        flex-grow: 1;
        overflow-y: auto;
        padding-top: 15px;
        padding: 10px;
        background: rgba(0, 0, 0, 0.2);
    }

    .content.map {
        display: block;
    }

    .content::-webkit-scrollbar {
        width: 10px;
    }
    
    .content::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.3);
    }
    
    .content::-webkit-scrollbar-thumb {
        background: #000;
    }
    
    .content::-webkit-scrollbar-thumb:hover {
        background: #000;
    }

}