.root {

}

.card {
    position: relative;
    display: flex;
    align-items: center;
    padding: 25px;
}

.favourite_phrase {
    font-size: 1.4rem;
    font-weight: bold;
    color: #FDC619;
    display: block;
    margin-bottom: 3px;
}

.heading {
    margin: 0;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.star {
    position: absolute;
    top: 25px;
    right: 25px;
}

.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.order_button {
    min-width: auto;
}

.order_button span > span {
    display: inline-block;
}

.order_button span + span {
    font-weight: 400;
}

.order_button_icon {
    margin-left: 15px;
}

@media (min-width:768px) {

    .root {
        padding: 16px;
        margin-top: 5px;
    }

    .card {
        min-height: 80px;
        padding: 10px 10px 10px 20px;
    }

    .star {
        position: absolute;
        top: 50%;
        left: 25px;
        width: 20px;
        height: 20px;
        transform: translate(-50%, -50%);
    }

    .star svg {
        width: 20px;
        height: 20px;
    }

    .heading_container {
        margin-left: 25px;
    }

    .open {
        margin-left: 25px;
    }

    .order_button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 15px;
        max-width: 250px;
    }

    .button_store_name {
        font-size: 1.4rem;
    }

    .button_order_label {
        font-size: 1.8rem;
    }

}

@media (min-width:1200px) {
    .star {
        position: unset;
        margin-right: 20px;
        transform: unset;
    }

    .heading_container {
        margin-left: 0px;
    }
}

@media (max-width:768px) {
    .root {
        margin-bottom: 40px;
    }

    .open {
        margin: 3px 0 15px;
    }

    .star {
        width: 25px !important;
        height: 25px !important;
    }
}