.root {
    margin-bottom: 50px;
}

.card {
    box-shadow: 0;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding:0;
}

.form {
    padding: 0px 20px 50px 20px;
}

.button {
    display: flex;
    justify-content: flex-end;
}

.loading {
    height: 110px;
}

.form_field { padding: 0; }

.send { width: 100%; }

@media (min-width:768px) {

    .card {
        box-shadow: 0 3px 14px 0 rgb(4 7 20 / 7%);
        border-radius: 20px;

    }

    .form {
        padding: 0px;
        margin: 0px 18px 34px 18px;
    }

    .form_field { padding: 0 16px; }

    .send { width: unset; }
}