.imageContainer {
    position: relative;
}

.imageContainer img {

}

.title {
    font-weight: 700;
    font-size: 2.0rem;
    margin-bottom: 15px;
    text-align: center;
}

.text {
    text-align: center;
}

.card {
    position: relative;
    border-bottom: #BB2840 3px solid;
}

@media (min-width: 768px) {
    .text_container {
        padding: 26px;
    }

    .imageContainer {
        height: 0;
        padding-bottom: 61.54%;
    }

    .chevron {
        display: none;
    }

    .banner_link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }

    .card { 
        height: 100%;
    }
}

@media (max-width: 768px) {
    .text_container {
        padding: 36px 40px 0 36px;
    }

    .title {
        font-size: 1.6rem;
        margin-bottom: 5px;
    }

    .text {
        font-size: 1.4rem;
    }

    .padding_10 {
        margin: -10px 0 -10px -10px;
    }

    .padding_20 {
        margin: -20px 0 -20px -20px;
    }

    .padding_30 {
        margin: -30px 0 -30px -30px;
    }

    .imageContainer img {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card {

    }

    .chevron {
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        width: 30px;
        height: 30px;
        margin: auto 0;
    }
}

@media (max-width: 600px) {
    .text_container {
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .text {
        max-width: 80%;
        overflow: hidden;
        display: inline-block;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin: 0;
    }

}