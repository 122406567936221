.root {
    position: relative;
    text-align: center;
    text-decoration: none;
    border: 0;
    font-weight: bold;
    border-radius: 6px;
    padding: 15px 20px;
    cursor: pointer;
    font-size: 1.6rem;
    min-width: 190px;
    min-height: 50px;
}

.root:disabled {
    cursor: default;
    pointer-events: none;
}

.normal {
    background: #F7C037;
    color: #ffffff;
    fill: #ffffff;
}

.primary {
    background: #BB2840;
    color: #ffffff;
    fill: #ffffff;
}

.secondary {
    background: #24D880;
    color: #ffffff;
    fill: #ffffff;
}

.loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}