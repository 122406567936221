.builder-image {

}

.builder-text p {
	margin: 0 0 15px 0 !important;
}

.builder-text p:last-child {
	margin-bottom: 0 !important;
}

.builder_header .builder-text {
	display: block;
	margin: 20px 20px;
}

.builder_header .builder-image {
	border-radius: 0px !important;
}

a.builder-block {
	text-decoration: none;
}

@media (min-width:768px) {

	.builder_header .builder-image {
		border-radius: 0px !important;
	}

	.builder_header .builder-text {
		margin: 24px 35px 20px 35px;
	}

}