
.card {
    padding: 36px;
    background: rgba(50, 50, 50, 0.76); 
}

.root {
    display: flex;
    flex-direction: column;
    position: relative; 
    width: 100%;
}

.heading { text-align: center; }

.input {
    margin: 0;
    padding: 15px;
    border: 1px solid #D1D7DF;
    background: #ffffff;
    color: #B1B7C0;
    outline: none;
    border-radius: 6px;
    box-shadow: none;
    width: 100%;
    margin-bottom: 15px;
}

.input_error {
    border: 1px solid #FF595C;
    background: #FFE8DD;
}

.button {
    text-align: center;
    text-decoration: none;
    border: 0;
    font-weight: bold;
    border-radius: 6px;
    padding: 15px;
    min-width: 100%;
    cursor: pointer;
    font-size: 1.6rem;
    background: #BB2840;
    color: #ffffff;
}

.mylocation {
    justify-content: center;
    color: #ffffff;
}