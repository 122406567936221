@media (max-width: 768px) {
    .header_logo {
        width: 170px;
        height: auto;
    }
}

@media (min-width:768px) {
    .header_logo {
        margin-left: 32px;
    }
}
