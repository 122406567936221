
.field {
    position: relative; 
    margin: 0 0 20px 0;
}

.label {
    display: inline-block;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 1.4rem;
}

.input {
    width: 100%;
    margin: 0;
    padding: 15px;
    border: 1px solid #D1D7DF;
    background: #F4F7F9;
    color: #B1B7C0;
    outline: none;
    border-radius: 6px;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.input_error,
:global(form.validated) .input:invalid {
    border: 1px solid #FF595C;
    background: #FFE8DD;
}
