.root {
    vertical-align: middle;
}

.responsive {
    max-width: 100%;
    height: auto;
}

.container {
    position: relative;
    padding: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
}

.container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.product { padding-bottom: 91%; }
.popular { padding-bottom: 61.47540983606557%; }
.location { padding-bottom: 62.12121212121212%; }
.category { padding-bottom: 108%; }
.cms_overview { padding-bottom: 42.47540983606557%; }

.category img {
    width: 100%;
    height: 100%;
    max-width: inherit;
}

.cms_overview img { width: 100%; }