.root {
    min-height: 190px;
    margin-bottom: 15px;
    background: #202020;
    padding: 25px;
}

.favorite {
    border: solid 1px #FDC619;
}

.heading {
    margin: 0;
    cursor: pointer;
}

.line {
    margin-bottom: 15px;
}

.attributes {
    display: flex;
    flex-direction: row;
}

.attributes div:first-child {
    margin-right: 15px;
}

.icon_text {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 9px;
}

.align_right {
    display: flex;
    justify-content: flex-end;
}

.note_container {
    display: flex;
    align-items: center;
}

.note {
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
	line-height: 1.8rem;
    font-style: italic;
    margin-top: 20px;
    color: #878D97;
}

.buttons {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.one_button {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.buttons button:nth-child(2) {
    margin-left: 15px;
}

.button {
    min-width: 50%;
    padding: 10px;
}

.city {
    margin-bottom: 10px;
}

@media (min-width:768px) {

    .root {
        background: rgba(0, 0, 0, 0.65);
    }

    .buttons {
        justify-content: flex-end;
        margin-top: 0px;
    } 

    .button {
        min-width: 110px;
    }

    .note_container { display: flex; }
    .note {
        margin-top: 0px;
        display: flex;
        justify-content: center;
        align-items: center; 
    }
}