.footer {
    background: #202020;
    padding: 54px 40px;
    box-sizing: border-box;
    overflow-x: hidden;
}

.bottom_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media (max-width: 768px) {
    .footer {
        padding: 54px 25px;
    }

    .top_container {
        padding-bottom: 40px;
        margin-bottom: 25px;
        position: relative;
    }

    .top_container:after {
        content: '';
        height: 1px;
        width: 100vw;
        background: #EAEFF4;
        position: absolute;
        bottom: 0;
        left: -25px;
        display: block;
    }
 
    .bottom_container {
        align-items: center;
    }
}