.card {
    padding: 10px;
    margin: 15px 0 32px 0;
}

.suggestion {
    margin: 30px 0 5px;
}

.image {
    border-radius: 10px;
}

.nav_wrapper nav {
    display: flex;
    justify-content: flex-start;
}

.nav_wrapper nav a {
    margin: 0;
}

.not_found_text {
    padding: 15px;
}

.nav_wrapper nav a {
    margin-top: 20px;
}

@media (min-width:768px) {
    .not_found_text {
        padding: 25px;
    }

    .nav_wrapper nav a:not(:last-child):after {
        content: '|';
        display: inline-block;
        margin: 0 6px;
    }

    .nav_wrapper nav a {
        margin-top: 10px;
    }

}