.card {
    background: unset;
    box-shadow: unset;
}

.info_container {
    position: relative;
}

.image_container {
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 3px 14px 0 rgb(4 7 20 / 7%);
}

.image_container:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -35%;
    height: 100%;
    background: linear-gradient(to bottom, transparent 0%, #000 100%);
}

.product_container { padding-top: 0px; }

.image { 
    border-radius: 0px;
    width: 100%;
}

.description_container {
    position: absolute;
    bottom: 0px;
    left: 16px;
    right: 16px;
    padding: 0 80px 15px 0 !important;
}

.description_container h3 { margin-bottom: 0px; color: #fff; }
.description_container p { font-size: 1.4rem; line-height: normal; color: #fff; }

.intro { justify-content: center; margin-bottom: 15px; }
.intro p:last-child { margin-bottom: 0; }

.label { position: absolute; top: 0; left: 0px; background: #BB2840; padding: 4px 10px; color: #fff; font-weight: bold; font-size: 1.5rem; }

.price { position: absolute; bottom: 10px; right: 15px; display: flex; align-items: center; justify-content: center; width: 85px; height: 85px; border-radius: 60px; background: #fff; box-shadow: 0 4px 16px 0 rgb(4 7 20 / 7%); color: #FD595C; font-size: 2.1rem; font-weight: bold; z-index: 99;}

.products {
    padding: 0px 26px;
}

.link { 
    display: flex;
    text-decoration: none; 
    background: rgba(50, 50, 50, 0.76);;
}

@media (min-width:768px) {

    .card {
        width: 100%;
        background: #fff;
        box-shadow: 0 3px 14px 0 rgb(4 7 20 / 7%);
        border-radius: 20px;
        overflow: hidden;
    }

    .image_container {
        position: relative;
        border-radius: 10px;
        padding: unset;
        overflow: unset;
        box-shadow: unset;
    }
    
    .image_container:after {
        content: unset;
        left: unset;
        right: unset;
        bottom: unset;
        height: unset;
        background: unset;
    }

    .description_container {
        position: relative;
        bottom: unset;
        left: unset;
        right: unset;
        padding: 36px 36px 36px 26px !important;
    }
    
    .description_container h3 { margin: 0 0 15px 0; color: unset; }
    .description_container p { font-size: unset;  line-height: 2.4rem; color: unset; }

    .intro { justify-content: center; margin-bottom: 35px; }
    
    .products_heading {
        margin-left: 0px;
    }
}
    
 