
.root {
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    grid-row-gap: 19px;
    grid-column-gap: 19px;
   
    text-decoration: none;

    margin: 30px 0 46px 0;
    padding: 0 16px;
}

.root * {
    text-decoration: none;
}

.vacancy {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: rgba(50, 50, 50, 0.76);
    border-bottom: #BB2840 3px solid;
    height: 320px;
    padding: 40px 20px 20px 20px;
}

.text {
    text-align: center;
    overflow: hidden;
    display: inline-block;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    max-height: 106px;
    overflow: hidden;
    text-decoration: none;
}

.button {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 40px;

}

@media (min-width: 768px) {
    .root {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width:1200px) {
    .root {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

}