.nav_wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
}

.nav_link {
    text-decoration: none;
    color: #fff;
    position: relative;
    margin-left: 25px;
    font-family: 'Bebas Neue';
    font-size: 2.0rem;
    text-transform: uppercase;
}

.active_link {
    color: #F7C037;
}

@media (max-width: 768px) {

    .nav_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        margin-left: auto;
    }

    .active_link:after {
        bottom: -29px;
    }

    .nav_wrapper {
        display: none;
    }
}

@media (min-width: 768px) {
    .nav_wrapper {
        padding-right: 32px;
    }
}

@media (min-width:1200px) {
    .nav_link {
        margin-left: 36px;
    }
}